import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  gradientText: {
    background: "linear-gradient(124deg, #0BBCED, #1252A5)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "#0000",
  },
}));

export default useStyles;
