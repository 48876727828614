import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./config/theme";
import "./index.css";
import PaymentAggregator from "./pages/PaymentAggregator";

const data = window.aautiPayData || {};

/**
 * A wrapper component for the AautiPay PaymentAggregator.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @returns {React.Element} A React element representing the AautiPay PaymentAggregator wrapped in a theme provider.
 */
function aautiPay(props) {
  return (
    <ThemeProvider theme={theme}>
      <PaymentAggregator {...props} />
    </ThemeProvider>
  );
}

ReactDOM.render(React.createElement(aautiPay, data), document.getElementById("aautiPay"));
