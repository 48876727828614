import React from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Grid,
} from "@mui/material";
import useStyles from "./styles";
import { currency_symbol } from "../staticData";
import { isArray, isEmpty } from "lodash";
import { Cancel } from "@mui/icons-material";

/**
 * ConfirmDialog React Component.
 *
 * @component
 * @param {Object} props - React component props.
 * @param {boolean} [props.visible=false] - Visibility state of the confirm dialog.
 * @param {Function} [props.handleModal=() => null] - Function to handle modal visibility changes.
 * @param {string} [props.title=""] - Title of the confirm dialog.
 * @param {boolean} [props.btnLoad=false] - Loading state for the confirm button.
 * @param {string} [props.subTitle=""] - Subtitle or additional description for the confirm dialog.
 * @param {string} [props.linearColorsName] - Name of linear gradient colors for the confirm button.
 * @param {boolean} [props.isGradientButton] - Flag indicating whether the confirm button has a gradient.
 * @param {string} [props.paymentGatwayFee] - Payment gateway fee information.
 * @param {Object} [props.paymentData] - Data related to the payment.
 * @param {number} [props.finalAmount] - Final amount to be confirmed.
 * @param {Object} [props.chargeData] - Data related to the charge or payment process.
 * @param {string} [props.changeBtnText] - Text to change the confirm button's label.
 * @param {boolean} [props.noCharge] - Flag indicating whether there is no charge.
 * @returns {JSX.Element} ConfirmDialog React component.
 */
function ConfirmDialog(props) {
  const {
    visible = false,
    handleModal = () => null,
    title = "",
    btnLoad = false,
    subTitle = "",
    linearColorsName,
    isGradientButton,
    paymentGatwayFee,
    paymentData,
    finalAmount,
    chargeData,
    changeBtnText,
    noCharge,
    primaryColor,
  } = props;
  const styles = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 320 : 500,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: sm ? 2 : 3,
  };

  return (
    <Modal
      open={visible}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <Grid
            container
            style={{
              justifyContent: "space-between",
            }}
          >
            <Typography className={styles.modalTitle}>{title || ""}</Typography>
            {changeBtnText && (
              <Cancel
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginTop: sm ? 6 : 8,
                  fontSize: sm ? 22 : 26,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (handleModal) {
                    handleModal();
                  }
                }}
              />
            )}
          </Grid>
          {subTitle !== "" && (
            <Grid container>
              <Typography
                variant="caption"
                style={{ marginBottom: 20, fontSize: 14 }}
              >
                <b>Note: </b> {subTitle || ""}
              </Typography>
            </Grid>
          )}

          {/* {changeBtnText && !noCharge && (
            <>
              <Typography fontWeight={"bold"}>Pricing Breakdown :-</Typography>
              <div className={styles.pricingBreakdown}>
                <Grid
                  container
                  justifyContent="space-between"
                  marginBottom={0.6}
                >
                  <span className="amount-type">Amount</span>
                  <span className={styles.pricingBreakdown_amount}>
                    {currency_symbol[paymentData?.currency]}
                    {chargeData?.withoutChargeAmount.toFixed(2)}
                  </span>
                </Grid>

                {isArray(chargeData?.mainChargeData) &&
                  !isEmpty(chargeData?.mainChargeData) &&
                  chargeData?.mainChargeData?.map((item, index) => {
                    const amountToAdd =
                      (item?.value * paymentData?.amount) / 100;
                    return (
                      <Grid
                        container
                        justifyContent="space-between"
                        marginBottom={0.6}
                      >
                        <span className="amount-type">{item?.name}</span>
                        <span className={styles.pricingBreakdown_amount}>
                          {currency_symbol[paymentData?.currency]}
                          {item?.slug === "payment_gateway_fee"
                            ? Number(paymentGatwayFee)?.toFixed(2)
                            : amountToAdd?.toFixed(2)}
                        </span>
                      </Grid>
                    );
                  })}
                <Grid
                  container
                  justifyContent="space-between"
                  className={styles.pricingBreakdown_final}
                >
                  <span className="amount-type">Final amount</span>
                  <span className={styles.pricingBreakdown_amount}>
                    {currency_symbol[paymentData?.currency]}
                    {Number(finalAmount)?.toFixed(2)}
                  </span>
                </Grid>
              </div>
            </>
          )} */}
          <div
            className={styles.splitViewStyle}
            style={{ justifyContent: "unset" }}
          >
            {!changeBtnText && (
              <Button
                variant="contained"
                sx={{
                  "&.MuiButton-containedPrimary": {
                    background: isGradientButton
                      ? `linear-gradient(124deg, ${linearColorsName[0]}, ${linearColorsName[1]})`
                      : primaryColor,
                    ":hover": {
                      background: isGradientButton
                        ? `linear-gradient(124deg, ${linearColorsName[0]}, ${linearColorsName[1]})`
                        : primaryColor,
                    },
                  },
                  minWidth: "140px !important",
                  fontWeight: "bold !important",
                  marginRight: 1,
                }}
                fullWidth
                onClick={() => {
                  if (handleModal) {
                    handleModal();
                  }
                }}
                disabled={btnLoad}
              >
                No
              </Button>
            )}
            <Button
              variant={"contained"}
              sx={{
                "&.MuiButton-containedPrimary": {
                  background: isGradientButton
                    ? `linear-gradient(124deg, ${linearColorsName[0]}, ${linearColorsName[1]})`
                    : primaryColor,
                  ":hover": {
                    background: isGradientButton
                      ? `linear-gradient(124deg, ${linearColorsName[0]}, ${linearColorsName[1]})`
                      : primaryColor,
                  },
                },
                minWidth: "140px !important",
                fontWeight: "bold !important",
              }}
              fullWidth
              onClick={() => {
                if (handleModal) {
                  handleModal(true);
                }
              }}
            >
              {btnLoad ? (
                <CircularProgress size={28} style={{ color: "#fff" }} />
              ) : changeBtnText ? (
                `${changeBtnText} ${
                  currency_symbol[paymentData?.currency]
                }${Number(finalAmount)?.toFixed(2)}`
              ) : (
                "Yes"
              )}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ConfirmDialog;
