import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { Grid, Modal } from '@mui/material'

/**
 * Renders a component to display payment status information.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.status - The payment status (e.g., "success", "fail").
 * @param {string} props.message - The message associated with the payment status.
 * @param {boolean} props.sm - A flag indicating whether to display the component in a small size.
 * @returns {JSX.Element} The rendered PaymentStatus component.
 */
const PaymentStatus = (props) => {
  const { status, message, sm, primaryColor } = props
  return (
    <>
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {status === 'loading' || status === 'url' ? (
          <div
            style={{
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CircularProgress
              sx={{
                mb: 3,
              }}
              color="primary"
              style={{
                fontSize: '100px !important',
                color: primaryColor,
              }}
            />
            <Typography
              fontWeight={600}
              sx={{
                mb: 1,
                fontSize: sm ? '22px' : '24px',
              }}
            >
              Processing Payment
            </Typography>
            <Typography
              color="#4b4b4b !important"
              fontSize={sm ? '18px' : '20px'}
              textAlign="center"
            >
              Please be patient. this process might take some time,
              {!sm && <br />}
              please do not hit refresh or browser back button or close this
              window
            </Typography>
          </div>
        ) : status === 'success' ? (
          <div
            style={{
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              src={
                'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1698837354-success.070338165a7c31845499.png'
              }
              alt="icon"
              height={sm ? 90 : 100}
              style={{
                marginBottom: '10px',
                color: primaryColor,
              }}
            />
            <Typography
              fontWeight={600}
              sx={{
                mb: 1,
                fontSize: sm ? '22px' : '24px',
              }}
            >
              Payment Successful !
            </Typography>
            <Typography
              color="#4b4b4b !important"
              fontSize={sm ? '18px' : '20px'}
            >
              Your payment has been successful!
            </Typography>
          </div>
        ) : status === 'wait' ? (
          <div
            style={{
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              src={
                'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1701334510-8090022.png'
              }
              alt="icon"
              height={sm ? 100 : 140}
              style={{
                marginBottom: '10px',
                color: primaryColor,
              }}
            />
            <Typography
              color="#4b4b4b !important"
              fontSize={sm ? '18px' : '20px'}
              textAlign="center"
            >
              {message ||
                'Your payment is in process. It will take 4-5 days for completion'}
            </Typography>
          </div>
        ) : (
          <div
            style={{
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              src={
                'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1698837385-fail.23663b807463adcfa471.png'
              }
              alt="icon"
              height={sm ? 90 : 100}
              style={{
                marginBottom: '10px',
                color: primaryColor,
              }}
            />
            <Typography
              fontWeight={600}
              sx={{
                mb: 1,
                fontSize: sm ? '22px' : '24px',
              }}
            >
              Payment Failed !
            </Typography>
            <Typography
              color="#4b4b4b !important"
              fontSize={sm ? '18px' : '20px'}
              textAlign={'center'}
            >
              {message || 'Any amount deducted will be refunded within 7 days'}
            </Typography>
          </div>
        )}
      </div>

      {status === 'url' && (
        <Modal
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 10,
          }}
          open={true}
        >
          <Grid
            container
            style={{
              maxWidth: 650,
              maxHeight: 650,
              minHeight: 400,
              backgroundColor: 'white',
              padding: 10,
              borderRadius: 4,
            }}
          >
            <iframe
              id="myIframe"
              src={message}
              referrerPolicy="unsafe-url"
              style={{ height: 'auto', width: '100%', border: 'none' }}
              title="3D secure"
            ></iframe>
          </Grid>
        </Modal>
      )}
    </>
  )
}

export default PaymentStatus
