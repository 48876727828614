import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    color: "#9D9D9D",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& label.Mui-focused": {
      color: "#0068EF",
    },

    "& label.Mui-error": {
      color: "red",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#0068EF",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#0068EF",
      },

      "&.Mui-error fieldset": {
        borderColor: "red",
      },
    },
  },
  cardTextField: {
    width: "100%",
    color: "#9D9D9D !important",

    "& label.Mui-focused": {
      color: "#0068EF",
    },

    "& label.Mui-error": {
      color: "red",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#0068EF",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#0068EF",
      },
      "&.Mui-error fieldset": {
        borderColor: "red",
      },
    },
  },
  savedCardGrid: {
    cursor: "pointer",
    marginTop: "10px",
    borderRadius: "5px",
    justifyContent: "space-between",
    alignItems: "center !important",
    display: "flex",
    position: "relative",
    border: "1px solid #9D9D9D",
    "&:hover": {
      border: "1.5px solid rgb(0, 104, 239)",
      "& $savedCardGridArrow": {
        color: "#0068EF",
      },
    },
  },
  savedCardGridArrow: {
    display: "flex",
    justifyContent: "end",
    padding: "0 5px",
    fontSize: "35px",
  },
  subTitle: {
    marginTop: 20,
    fontWeight: "bold !important",
  },
  cardDetailsFields: {
    width: "100%",
    borderRadius: "5px",
    border: "1px solid #9D9D9D !important",
    outline: "none !important",
    boxShadow: "none !important",
  },
  pricingBreakdown: {
    margin: "5px 0",
    border: "2px solid #9D9D9D",
    borderRadius: "5px",
    // backgroundColor: "red",
    padding: "20px",
    textTransform: "capitalize",
  },
  pricingBreakdown_final: {
    color: "#0068EF !important",
  },
  pricingBreakdown_amount: {
    fontWeight: "bold",
  },
  payButton: {
    marginTop: "10px !important",
    width: 200,
    border: "none",
    outline: "none",
    boxShadow: "none",
    color: "#FFFFFF !important",
    fontWeight: "bold !important",
  },
}));

export default useStyles;
