import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  splitViewStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalBtnStyle: {},
  modalTitle: {
    width: "90%",
    fontSize: "24px !important",
    marginBottom: "20px !important",
    lineHeight: "36px !important",
    "@media (max-width:1024px)": {
      fontSize: "20px !important",
    },
    "@media (max-width:768px)": {
      lineHeight: "30px !important",
    },
  },
  pricingBreakdown: {
    margin: "10px 0px 30px 0px",
    textTransform: "capitalize",
  },
  pricingBreakdown_final: {
    color: "#0068EF !important",
  },
  pricingBreakdown_amount: {
    fontWeight: "bold",
  },
}));

export default useStyles;
