import {
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getApiDataProgressPayment } from '../APIHelper'
import { isArray, isEmpty } from 'lodash'
import CardDetails from '../CardDetails'
import PaymentStatus from '../PaymentStatus'
import { loadStripe } from '@stripe/stripe-js'
import useStyles from './styles'
import { Elements } from '@stripe/react-stripe-js'
import { useTheme } from '@emotion/react'

/**
 * PaymentAggregator component for handling various payment methods and aggregating them.
 *
 * @component
 * @param {Object} props - Component properties.
 * @returns {JSX.Element} - React JSX element representing the PaymentAggregator component.
 */
const PaymentAggregator = (props) => {
  const [paySuccess, setPaySuccess] = useState(false)
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const lg = useMediaQuery(theme.breakpoints.down('lg'))

  const {
    PaymentType = '', //require => one_time, subscription, custom_subscription
    paymentData = {
      name: 'Ronak',
      amount: 50,
      email: 'krunalp1993@gmail.com',
      country_code: 'US',
      app_token: '377ae0e5639a2c6ae3c7d98439a63e36902c2d34',
      currency: 'USD',
      transaction_code: 'txn_1234567',
    }, //require
    onPaymentDone = () => {
      setTimeout(() => setPaySuccess(false), 3000)
    },
    onCloseClick = () => {},
    appCharges = [
      {
        id: 1,
        name: 'Platform Fees',
        slug: 'platform_fees',
        type: 'exclude' === 'exclude' ? 'exclusive' : 'inclusive',
        value: 2,
      },
    ],
    noCharge = false,
    pluginURL = 'staging', //staging, dev, prodapi
    changeBtnText = 'Pay',
    showHeader = true,
    primaryColor = '#0068EF',

    // For gradient
    isGradientButton = true,
    linearColorsName = ['#0BBCED', '#1252A5'],
    themeColor = '#F5F9FF',
  } = props

  const styles = useStyles()

  const stripePromise = loadStripe(
    'pk_test_51Lp74WLvsFbqn13LVwHWLWuHOMzx3Jyn8dZSAVjGf9oIetpNOgvbbMMRjp5WRRheejXuSftYmD9uoebv2y0Rdm1h003RC3YCS6'
  )

  const liveUrl = 'https://aautidev.aautipay.com/plugin/'
  // const liveUrl = 'http://localhost:3000/plugin/'

  const [failMessage, setFailMessage] = useState('')

  const [pageLoade, setPageLoader] = useState(true)
  const [chargeData, setChargeData] = useState({})

  const [activeIndex, setActiveIndex] = useState([])
  const [paymentMethod, setPaymentMethod] = useState([])

  const isCard = paymentMethod?.filter(
    (item) => item?.type === 'card' && !isEmpty(item?.charge_object)
  )
  const isBank = paymentMethod?.filter(
    (item) => item?.type === 'bank' && !isEmpty(item?.charge_object)
  )
  const isOther = paymentMethod?.filter(
    (item) =>
      item?.type === 'digital wallet' &&
      !isEmpty(item?.charge_object) &&
      item['payment_method.payment_method'] !== 'Apple Pay' &&
      item['payment_method.payment_method'] !== 'Google Pay'
  )

  const [paymentSelectedType, setPaymentSelectedType] = useState('')

  useEffect(() => {
    getAccessToken()
  }, [])

  useEffect(() => {
    isEmpty(paymentSelectedType) &&
      setPaymentSelectedType(
        !isEmpty(isOther)
          ? 'digital wallet'
          : !isEmpty(isBank)
          ? 'bank'
          : 'card'
      )
  }, [isOther, isCard])

  const iframe = document.getElementById('myIframe')
  useEffect(() => {
    if (iframe) {
      // Listen for the iframe's load event
      iframe.addEventListener('load', function () {
        // Access the iframe's contentWindow and its location object
        const iframeContentWindow = iframe.contentWindow
        const url = iframeContentWindow.location.href
        console.log('Current iframe URL: test in both safari chrome' + url)

        if (url.includes('payment_intent')) {
          checkStripePayment(url)
        } else if (url.includes('success')) {
          console.log('Payment Done.')
          setPaySuccess('success')
          onPaymentDone()
          // Close the InAppBrowser
        } else if (url.includes('failure')) {
          setPaySuccess('fail')
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
        }

        if (url) {
          setFailMessage('')
        }
      })
    }
  }, [iframe])

  // Async function for checking the status of a Stripe payment
  /**
   * Check the status of a Stripe payment using the provided URL.
   *
   * @async
   * @function
   * @param {string} url - The URL containing payment information.
   * @returns {Promise<void>} - A Promise that resolves once the payment status is checked.
   */
  async function checkStripePayment(url) {
    // Regular expression to extract query parameters from the provided URL
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match
    // Loop through the URL and extract query parameters
    while (!isEmpty(url) && (match = regex.exec(url))) {
      params[match[1]] = match[2]
    }
    // Retrieve the Stripe secret key from the extracted parameters
    const stripeSecretKey = params?.key

    // Make a GET request to the Stripe API to retrieve payment intent information
    fetch(
      `https://api.stripe.com/v1/payment_intents/${params?.payment_intent}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${stripeSecretKey}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Check if the payment status is "succeeded"
        if (data?.status == 'succeeded') {
          // Set payment success status to "success"
          setPaySuccess('success')
          // Call the onPaymentDone function
          onPaymentDone()
        } else {
          // If payment status is not "succeeded," set payment success status to "fail"
          setPaySuccess('fail')
          // Set a timeout to reset payment success status after 5 seconds
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
        }
        // Handle the response data here
      })
      .catch((error) => {
        // Handle any errors here
      })
  }

  // Asynchronous function to obtain an access token
  /**
   * Asynchronously retrieves an access token from the server for the application.
   *
   * @async
   * @function
   * @returns {Promise<void>} - A Promise that resolves once the access token is obtained.
   */
  async function getAccessToken() {
    // Step 1: Set the page loader to indicate that loading is in progress
    setPageLoader(true)

    // Step 2: Prepare data to be sent in the API request
    const data = {
      app_token: paymentData?.app_token, // Retrieve app token from paymentData
      countryCode: paymentData?.country_code, // Retrieve country code from paymentData
    }

    try {
      // Step 3: Make an asynchronous API request to the login endpoint
      const response = await getApiDataProgressPayment(
        `${liveUrl}login`, // URL for the login endpoint
        'POST', // HTTP method (POST)
        data, // Data to be sent in the request body
        chargeData?.auth_token // Authorization token from chargeData (optional chaining used)
      )
      // Step 4: Check if the API response indicates an error (status is false)
      if (response?.status == false) {
        // Step 5: If an error occurred, set the page loader to false
        setPageLoader(false)
      } else {
        // Step 6: If the API response is successful, proceed to handle the data

        // Step 7: Check if appCharges is not empty, then apply charges
        !isEmpty(appCharges)
          ? chargesApply(
              appCharges,
              response?.data?.auth_token,
              response?.data?.userData?.portal_mode
            )
          : // Step 8: If appCharges is empty, get app charges
            getAppCharges(
              response?.data?.auth_token,
              response?.data?.userData?.id,
              response?.data?.userData?.portal_mode
            )
      }
    } catch (error) {
      // Step 9: Handle any errors that occurred during the API request

      // Step 10: Set the page loader to false since an error occurred
      setPageLoader(false)
      console.log('error:', error)
    }
  }

  // Function to calculate and process charge-related data
  /**
   * Applies charges to the payment data, calculates amounts, and updates the charge data state.
   *
   * @function
   * @param {Array} mainChargeData - An array containing main charge data.
   * @param {string} token - The authentication token.
   * @param {string} mode - The mode of the charge (e.g., "test" or "production").
   * @returns {void}
   */
  const chargesApply = (mainChargeData, token, mode) => {
    // Initialize variables to store percentage sums for exclusive and inclusive charges
    let sum_per = 0
    let sum_inc_per = 0

    // Filter exclusive charges from mainChargeData
    const filExc = isArray(mainChargeData)
      ? mainChargeData?.filter((V) => V.type === 'exclusive')
      : []

    // Filter inclusive charges from mainChargeData
    const filInc = isArray(mainChargeData)
      ? mainChargeData?.filter((V) => V.type === 'inclusive')
      : []

    // Calculate sum of percentages for exclusive charges excluding "payment_gateway_fee"
    isArray(filExc) &&
      !isEmpty(filExc) &&
      filExc.forEach((v) => {
        if (v.slug !== 'payment_gateway_fee') {
          sum_per = sum_per + v.value
        }
      })
    // Calculate the amount to add based on the sum of exclusive percentages
    const amountToAdd = (sum_per * paymentData?.amount) / 100

    // Calculate sum of percentages for inclusive charges excluding "payment_gateway_fee"
    isArray(filInc) &&
      !isEmpty(filInc) &&
      filInc.forEach((v) => {
        if (v.slug !== 'payment_gateway_fee') {
          sum_inc_per = sum_inc_per + v.value
        }
      })
    const amountToMin = (sum_inc_per * paymentData?.amount) / 100

    const isPaymentGateWay =
      isArray(filExc) &&
      !isEmpty(filExc) &&
      filExc.find((v) => v.slug === 'payment_gateway_fee')

    // Construct chargeData1 object with calculated values
    const chargeData1 = {
      isPaymentGateWay: !isEmpty(isPaymentGateWay),
      exclusive_Data: filExc,
      withChargeAmount: paymentData?.amount + amountToAdd,
      withoutChargeAmount: paymentData?.amount - amountToMin,
      auth_token: token,
      mainChargeData: mainChargeData,
      mode: mode,
      amountToAdd: amountToAdd,
    }
    // Set the calculated charge data using the setChargeData function
    setChargeData(chargeData1)
    // Call the getPaymentOption function with the updated token, total amount, and mode
    getPaymentOption(token, paymentData?.amount + amountToAdd, mode)
  }

  // Asynchronous function to fetch application charges
  /**
   * Fetches application charges from the server, and applies the charges using the chargesApply function.
   *
   * @async
   * @function
   * @param {string} token - The authentication token.
   * @param {string} useID - The user ID.
   * @param {string} mode - The mode of the charge (e.g., "test" or "production").
   * @returns {Promise<void>} A Promise that resolves when the process is complete.
   */
  async function getAppCharges(token, useID, mode) {
    // Make a GET request to the server to fetch application charges
    fetch(`${liveUrl}get-app-charges/${useID}/${paymentData?.country_code}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        // Check if the API response indicates success (status is true)
        if (data?.status) {
          // Apply charges using the chargesApply function with the fetched data
          chargesApply(data?.data, token, mode)
        } else {
          // If the response indicates an error, set the page loader to false
          setPageLoader(false)
        }
        // Handle the response data here
      })
      .catch((error) => {
        // Handle any errors here
        setPageLoader(false)
      })
  }

  // Function to fetch payment options
  /**
   * Fetches payment options from the server based on the provided parameters,
   * and updates the payment methods, active index, and page loader state accordingly.
   *
   * @param {string} auth_token - The authentication token.
   * @param {number} amountToAdd - The amount to add (considering additional charges).
   * @param {string} mode - The mode of the charge (e.g., "test" or "production").
   * @returns {void}
   */
  const getPaymentOption = (auth_token, amountToAdd, mode) => {
    try {
      // Make a GET request to the server to fetch payment options
      fetch(
        `${liveUrl}payment-options/${
          paymentData.country_code
        }?method=${''}&mode=${mode}&amount=${
          noCharge ? paymentData?.amount : amountToAdd
        }&currency=${paymentData?.currency}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth_token}`,
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // Check if the API response indicates success (status is true)
          if (response?.status) {
            // Set payment methods using the setPaymentMethod function
            setPaymentMethod(response?.data)
            // Set the active index based on the minimum index from the response
            setActiveIndex(response?.minimum_index)
            // Set the page loader to false since the operation is complete
            setPageLoader(false)
          } else {
            // Handle cases where the response indicates an error

            // Check for specific unauthorized error
            if (
              response?.statusCode === 401 &&
              response?.message ===
                'Unauthorized. Access token is missing or invalid.'
            ) {
            }
            // Set the page loader to false since an error occurred
            setPageLoader(false)
          }
        })
        .catch((err) => {
          // Handle any errors that occurred during the API request
          console.log(err.message)
          // Set the page loader to false since an error occurred
          setPageLoader(false)
        })
    } catch (error) {
      setPageLoader(false)
      console.log('ERRRRR', error)
    }
  }

  return (
    <Elements stripe={stripePromise}>
      {showHeader && (
        <div
          style={{
            padding: 10,
            backgroundColor: primaryColor,
            borderRadius: 4,
          }}
        >
          <Typography
            style={{ color: '#fff', fontWeight: 'bold', fontSize: 20 }}
          >
            Payment Options
          </Typography>
        </div>
      )}
      <Grid
        container
        style={{
          padding: showHeader ? (lg ? '10px 10px' : '20px 20px') : 'unset',
        }}
      >
        {paySuccess ? (
          <div
            style={{
              display: 'flex',
              height: '100vh',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <PaymentStatus
              primaryColor={primaryColor}
              status={paySuccess}
              message={failMessage}
              sm={sm}
            />
          </div>
        ) : (
          <>
            <Grid item xs={12} lg={2} style={{}}>
              <Paper
                elevation={2}
                style={{
                  borderRadius: 4,
                  borderWidth: 1,
                  height: 'auto',
                  width: '100%',
                }}
              >
                <Tabs
                  value={paymentSelectedType}
                  orientation="vertical"
                  scrollButtons={false}
                  onChange={(event, newValue) => {
                    setPaymentSelectedType(newValue)
                  }}
                  sx={{
                    '& .MuiTabs-indicator': {
                      backgroundColor: primaryColor, // Change the color of the indicator
                      width: '6px', // Change the width of the indicator
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                      left: 0,
                      top: '50%',
                    },
                  }}
                >
                  {!isEmpty(isOther) && (
                    <Tab
                      sx={{
                        '&.MuiButtonBase-root': {
                          padding: '0px 0px 0px 16px',
                          marginTop: '10px !important',
                          maxWidth: 'unset',
                          alignItems: 'flex-start',
                        },
                        '&.Mui-selected': {
                          color: primaryColor,
                          backgroundColor: themeColor,
                        },
                      }}
                      label={
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                        >
                          Digital Wallets
                        </Typography>
                      }
                      value="digital wallet"
                    />
                  )}
                  {!isEmpty(isCard) && (
                    <Tab
                      sx={{
                        '&.MuiButtonBase-root': {
                          padding: '0px 0px 0px 16px',
                          maxWidth: 'unset',
                          alignItems: 'flex-start',
                        },
                        '&.Mui-selected': {
                          color: primaryColor,
                          backgroundColor: themeColor,
                        },
                      }}
                      label={
                        <Typography
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          Credit / Debit card
                        </Typography>
                      }
                      value="card"
                    />
                  )}
                  {!isEmpty(isBank) && (
                    <Tab
                      sx={{
                        '&.MuiButtonBase-root': {
                          padding: '0px 0px 0px 16px',
                          maxWidth: 'unset',
                          marginBottom: '10px !important',
                          alignItems: 'flex-start',
                        },
                        '&.Mui-selected': {
                          color: primaryColor,
                          backgroundColor: themeColor,
                        },
                      }}
                      label={
                        <Typography
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          Net Banking
                        </Typography>
                      }
                      value="bank"
                    />
                  )}
                </Tabs>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={10}>
              {pageLoade ? (
                <div
                  style={{
                    display: 'flex',
                    height: '200px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress style={{ color: primaryColor }} />
                </div>
              ) : isEmpty(paymentMethod) ? (
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
                    No Data
                  </Typography>
                </div>
              ) : (
                <CardDetails
                  {...props}
                  isGradientButton={isGradientButton}
                  linearColorsName={linearColorsName}
                  onPaymentDone={onPaymentDone}
                  paymentMethod={paymentMethod}
                  paymentData={paymentData}
                  liveUrl={liveUrl}
                  activeIndex={activeIndex}
                  noCharge={noCharge}
                  primaryColor={primaryColor}
                  chargeData={chargeData}
                  setPaySuccess={(type, message) => {
                    setPaySuccess(type)
                    setFailMessage(message)
                  }}
                  paymentSelectedType={paymentSelectedType}
                  changeBtnText={changeBtnText}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Elements>
  )
}

export default PaymentAggregator
